import React, { useState, useEffect } from "react";
import "../assets/css/about.css";
import Fade from "react-reveal/Fade";
import Footer from "../wrappers/Footer";
import Loader from "../wrappers/Loader";
import Header from "../wrappers/Header";
import { Helmet } from "react-helmet";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";

const Faq = () => {
  // FAQ verilerini API'dan almak için state tanımlamaları
  const [faqs, setFaqs] = useState([]); // Başlangıçta faqs boş array
  const [isLoading, setIsLoading] = useState(true); // Yüklenme durumu için
  const languageData = useSelector((state) => state.data);
  const currentLanguage = useSelector((state) => state.language);
  const [showMenu, setShowMenu] = useState(false);
  const [showFooter, setShowFooter] = useState(true);
  const language = useSelector((state) => state.language);
  const url = `${process.env.REACT_APP_BASE_URL}/faq/${language}`;
  const { language: x } = useParams();

  const serverUrl = process.env.REACT_APP_SERVER_BASE_URL;

  // API'dan FAQ verilerini çekme fonksiyonu
  const fetchFaqs = async () => {
    try {
      // API'ya istek atarak verileri çekiyoruz
      const response = await fetch(serverUrl+ "/faq");
      const data = await response.json();
      if (data ) {
        setFaqs(data); // Veriler varsa state'e kaydediyoruz
      } else {
        setFaqs([]); // Veri yoksa boş array set ediyoruz
      }
      setIsLoading(false); // Yükleme durumu sona erdiği için isLoading false yapıyoruz
    } catch (error) {
      console.error("FAQ verileri alınırken hata oluştu:", error);
      setIsLoading(false); // Hata durumunda yükleme durumu sona eriyor
    }
  };

  // Bileşen yüklendiğinde veriyi çekmek için useEffect kullanıyoruz
  useEffect(() => {
    fetchFaqs();
  }, []);

  // Yanlış dil kodu varsa yönlendirme
  useEffect(() => {
    if (x && !["en", "fr", "ru", "tr"].includes(x)) {
      window.location.pathname = `/404`; // Geçersiz dil kodunda 404 sayfasına yönlendirme
    } else if (x && window.location.pathname.endsWith("/")) {
      const newPathname = window.location.pathname.slice(0, -1); // Fazladan '/' varsa onu kaldır
      window.location.pathname = newPathname;
    }
  }, [x]);

  // Menü görünürlüğünü değiştiren fonksiyon
  const toggleMenu = () => {
    setShowMenu(!showMenu);
    setShowFooter(showMenu);
  };

  // HTML içerisindeki linklerin dil kodlarına göre düzenlenmesi
  const transform = (node) => {
    if (node.type === "tag" && node.name === "a") {
      return (
        <a href={node.attribs.href.replace(":language", currentLanguage)}>
          {node.children[0].data}
        </a>
      );
    }
  };

  return (
    <>
      <Helmet>
        <title>{languageData.faq}</title>
        <meta
          name="description"
          content="Belirli sorularınız varsa veya daha fazla yardıma ihtiyacınız olursa, ekibimiz size her adımda destek olmaya hazır."
        />
        <link rel="canonical" href={url} />
        <link rel="icon" href="/favicon.ico" />
      </Helmet>
      <Loader />
      <Fade top delay={800}>
        <div className="downing-page-div">
          <Header showMenu={showMenu} toggleMenu={toggleMenu} />
          <div className="container text-header d-flex justify-content-center  flex-column align-items-center">
            {showFooter && (
              <>
                <div className="col-12 title-sticky">
                  <h1 className="title-main">{languageData.FAQ}</h1>
                </div>
                <div className="bg-main d-flex justify-content-center flex-column align-items-center">
                  <div className="col-lg-9 col-12 bg-gray text-white">
                    <p style={{ fontStyle: "italic" }}>
                      {languageData.faq_text_1}
                    </p>
                  </div>

                  <div className="col-lg-9 col-12 mt-5 mb-5 ">
                    {/* Yüklenme durumu kontrolü */}
                    {isLoading ? (
                      <p>FAQs yükleniyor...</p> // Veriler yükleniyorsa "yükleniyor" metni göster
                    ) : (
                      faqs && faqs.length > 0 ? (
                        <Accordion preExpanded={["0"]}>
                          {/* API'dan gelen verileri haritalayarak Accordion bileşenine yerleştiriyoruz */}
                          {faqs.map((faq, index) => (
                            <AccordionItem key={faq.faq_no} uuid={`${index}`}>
                              <AccordionItemHeading>
                                <AccordionItemButton>
                                  {currentLanguage === "en"
                                    ? faq.question_en
                                    : currentLanguage === "fr"
                                    ? faq.question_fr
                                    : currentLanguage === "ru"
                                    ? faq.question_ru
                                    : currentLanguage === "tr"
                                    ? faq.question_tr
                                    : faq.question}
                                </AccordionItemButton>
                              </AccordionItemHeading>
                              <AccordionItemPanel>
                                <p>
                                  {ReactHtmlParser(
                                    currentLanguage === "en"
                                      ? faq.answer_en
                                      : currentLanguage === "fr"
                                      ? faq.answer_fr
                                      : currentLanguage === "ru"
                                      ? faq.answer_ru
                                      : currentLanguage === "tr"
                                      ? faq.answer_tr
                                      : faq.answer,
                                    { transform }
                                  )}
                                </p>

                                {/* Eğer buton varsa, gösterilecek bağlantı ve metni oluştur */}
                                {faq.button === "true" && (
                                  <a
                                    rel="noreferrer"
                                    aria-label="faq"
                                    target="_blank"
                                    href={
                                      faq.btn_href.endsWith(".pdf")
                                        ? language === "tr"
                                          ? `/pdf_tr/${faq.btn_href}`
                                          : language === "ru"
                                          ? `/pdf_ru/${faq.btn_href}`
                                          : language === "fr"
                                          ? `/pdf_fr/${faq.btn_href}`
                                          : `/pdf_en/${faq.btn_href}`
                                        : `/${faq.btn_href}/${language}`
                                    }
                                  >
                                    <p className="button-focus mt-2">
                                      {currentLanguage === "en"
                                        ? faq.btn_text_en
                                        : currentLanguage === "fr"
                                        ? faq.btn_text_fr
                                        : currentLanguage === "ru"
                                        ? faq.btn_text_ru
                                        : currentLanguage === "tr"
                                        ? faq.btn_text_tr
                                        : faq.btn_text}
                                    </p>
                                  </a>
                                )}
                              </AccordionItemPanel>
                            </AccordionItem>
                          ))}
                        </Accordion>
                      ) : (
                        <p>Soru bulunamadı</p> // Eğer `faqs` boşsa gösterilecek mesaj
                      )
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
          {showFooter && <Footer />}
        </div>
      </Fade>
    </>
  );
};

export default Faq;
